import React, { useEffect, useRef } from "react";
import s from "./BgLine13.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const BgLine13 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      gsap.from(lineRef.current, {
        drawSVG: "0% 0%",
        duration: 1,
        ease: "none",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: "125% 35%+=100px",
          once: true
        }
      });
    }, 1500);
    return () => timer && clearTimeout(timer);
  }, []);

  return (
    <div id="section13__draw" className={s.section13__draw}>
      <svg
        className={s.path__draw__1}
        width="527"
        height="516"
        viewBox="0 0 527 516"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="section__draw"
          ref={lineRef}
          opacity="0.2"
          d="M0 1H125.959C236.416 1 325.959 90.543 325.959 201V314.839C325.959 425.296 416.543 514.839 527 514.839V514.839"
          stroke="#1B52AD"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};
