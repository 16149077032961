import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Seo } from "components/contentful/seo/Seo";
import { Hero } from "components/contentful/hero/Hero";
import { MediaTextContentful } from "components/contentful/media-text/MediaText";
import { MediaTextFeatures } from "components/contentful/media-text-features/MediaTextFeatures";
import { InfoListContentful } from "components/contentful/info-list/InfoList";
import { BgGradient } from "components/bg-svgs/gradients/BgGradient";
import { WrapperBgs } from "components/container/WrapperBgs";
import { BgLine1 } from "components/bg-svgs/lines/homepage-connect/bg-line-1/BgLine1";
import { BgLine2 } from "components/bg-svgs/lines/homepage-connect/bg-line-2/BgLine2";
import { UIContext } from "context/ui";
import { ArticlesBoard } from "components/blog/articles-board/ArticlesBoard";
import gsap from "gsap";
import DrawSVGPlugin from "../utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitText from "../utils/SplitText/SplitText";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

import { BgLine3 } from "components/bg-svgs/lines/homepage-connect/bg-line-3/BgLine3";
import { BgLine4 } from "components/bg-svgs/lines/homepage-connect/bg-line-4/BgLine4";
import { VideoItem } from "components/video-item/VideoItem";
import { BillboardTextContentful } from "components/contentful/billboard-text/BillboardText";
import { PartnersGrid } from "components/partners-grid/PartnersGrid";
import { BgLine12 } from "../components/bg-svgs/lines/home/bg-line-12/BgLine12";
import { BgLine13 } from "../components/bg-svgs/lines/home/bg-line-13/BgLine13";
import { BgLine14 } from "../components/bg-svgs/lines/home/bg-line-14/BgLine14";
import { browserTargeting } from "utils/browserTargeting";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSRulePlugin);

export default ({ data }: any) => {
  const { isMobile, isDesktop } = useContext(UIContext);
  const { contentfulData } = data;
  const seo = contentfulData.seo;

  const browser = browserTargeting();
  const [blogs, setBlogs] = useState([]);
  const getHubspotBlogs = async () => {
    const action = `/.netlify/functions/hubspotBlogs`;
    const res = await fetch(action);
    const { results } = await res.json();
    setBlogs(results);
  };
  useEffect(() => {
    getHubspotBlogs();
  }, []);
  return (
    <>
      {seo && <Seo seo={seo} />}
      <BgGradient />

      <WrapperBgs overflowMobile={true}>
        <Hero
          clearPaddingBottom={true}
          hero={{ ...contentfulData.hero, clearPaddingBottom: true }}
        />

        <VideoItem
          autoplay={true}
          loop={true}
          item={contentfulData.body[0]}
          borderBottomLeftRadius={true}
          forceNoControls={true}
        />

        {isDesktop && <BgLine1 />}
      </WrapperBgs>

      <WrapperBgs>
        {isDesktop && <BgLine2 />}
        <BillboardTextContentful
          billboardText={contentfulData.body[1]}
          style={{
            paddingBottom: 50
          }}
        />
      </WrapperBgs>

      <WrapperBgs>
        {isDesktop && <BgLine3 />}
        <MediaTextFeatures
          mediaTextFeatures={contentfulData.body[2]}
          titleInside={true}
          allFeaturesLeft={true}
          noIcons={true}
          hasParallax={false}
        />
      </WrapperBgs>

      <WrapperBgs>
        <MediaTextFeatures
          mediaTextFeatures={contentfulData.body[3]}
          titleInside={false}
          hideIcons={false}
          allFeaturesLeft={true}
          limitedTitleWidth={true}
          hasParallax={false}
        />
        {isDesktop && <BgLine4 />}
      </WrapperBgs>

      <WrapperBgs>
        <MediaTextContentful
          mediaText={contentfulData.body[4]}
          layout="column"
          hasParallax={false}
        />
      </WrapperBgs>
      <WrapperBgs>
        <PartnersGrid listData={contentfulData.body[5]} />
      </WrapperBgs>

      <WrapperBgs>
        <InfoListContentful
          titlePlacement="inside"
          borderRadius="bottomLeft"
          imageAlignment="left"
          titleAlignTop={false}
          lessPaddingTop={false}
          lessPaddingBottom={true}
          informationAlignedTop={true}
          smallVideos={true}
          evenColumns={true}
          borderBottomLeftRadius={true}
          infoList={contentfulData.body[6]}
        />
      </WrapperBgs>
      <WrapperBgs>
        {isDesktop && <BgLine12 />}

        <MediaTextContentful
          mediaText={contentfulData.body[7]}
          layout="column"
          hasParallax={false}
        />
      </WrapperBgs>

      <WrapperBgs>
        {isDesktop && <BgLine13 />}

        <MediaTextContentful
          mediaText={contentfulData.body[8]}
          layout="column"
          bottomLeftRadius={true}
          topRadius={true}
          hasParallax={false}
        />
      </WrapperBgs>
      {blogs.length > 0 && (
        <WrapperBgs>
          <ArticlesBoard
            item={contentfulData.body[9]}
            list={{
              items: blogs
            }}
            extraSpacingBottom={true}
          />
        </WrapperBgs>
      )}

      <WrapperBgs>
        {isDesktop && <BgLine14 />}

        <MediaTextContentful
          layout="column"
          mediaText={contentfulData.body[10]}
          hasParallax={false}
        />
      </WrapperBgs>
    </>
  );
};

export const query = graphql`
  {
    contentfulData: contentfulPage(name: { eq: "Home Page 2023_03" }) {
      name
      seo {
        description
        title
        twitterImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        facebookImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        no_index
        no_follow
        name
      }
      body {
        ... on ContentfulMediaText {
          id
          autoplay
          showPlayButton
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          cta {
            label
            href
          }
          title
          text {
            raw
          }
          sectionBackground {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
            description
          }
        }
        ... on ContentfulBillboard {
          id
          title
          text {
            raw
          }
          cta {
            href
            label
          }
        }
        ... on ContentfulMediaTextFeatures {
          id
          title
          alignImageLeft
          items {
            description {
              raw
            }
            icon {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
          }
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulMediaTextFeatures {
          id
          title
          text {
            raw
          }
          alignImageLeft
          items {
            description {
              raw
            }
            icon {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
          }

          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }

        ... on ContentfulMediaText {
          id
          videoUrl
          cta {
            label
            href
          }
          title
          text {
            raw
          }
          sectionBackground {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
            description
          }
        }
        ... on ContentfulList {
          id
          title
          subLists {
            items {
              image {
                description
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
              title
              text {
                raw
              }
              video {
                file {
                  url
                }
                description
              }
            }
          }
        }
        ... on ContentfulList {
          id
          title
          text {
            raw
          }
          cta {
            label
            href
          }
          items {
            title
            text {
              raw
            }
            quote {
              author
              text {
                raw
              }
              avatar {
                description
                file {
                  details {
                    image {
                      width
                      height
                    }
                  }
                  url
                }
              }
            }
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
            }
            video {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
          image {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            description
          }
        }

        ... on ContentfulInfoList {
          id
          title
          description {
            raw
          }
          cta {
            label
            href
          }
          items {
            image {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
            text {
              raw
            }
            video {
              file {
                url
              }
              description
            }
          }
          subtitle
        }
        ... on ContentfulMediaText {
          id
          videoUrl
          showPlayButton
          autoplay
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulInfoList {
          id
          title
          description {
            raw
          }
          cta {
            label
            href
          }
          items {
            image {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
            text {
              raw
            }
            video {
              file {
                url
              }
              description
            }
          }
          subtitle
        }
        ... on ContentfulList {
          id
          title
          items {
            title
            text {
              raw
            }
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
            }
            tag
            date
            cta {
              label
              href
            }
          }
        }
        ... on ContentfulMediaText {
          id
          videoUrl
          showPlayButton
          autoplay
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        title
        text {
          raw
        }
      }
    }
  }
`;
