
        // class-names-loader generated code for /opt/build/repo/src/components/bg-svgs/lines/home/bg-line-13/BgLine13.scss
        import classNames from "!../../../../../../node_modules/classnames/bind";
        import locals from "!!../../../../../../node_modules/mini-css-extract-plugin/dist/loader.js??ruleSet[1].rules[13].use[1]!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[13].use[2]!../../../../../../node_modules/gatsby/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[13].use[3]!../../../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[13].use[4]!./BgLine13.scss";
        import assign from "!../../../../../../node_modules/@ecomfe/class-names-loader/dist/assign.js";

        var css = classNames.bind(locals);
        assign(locals, css);

        export default css;
    