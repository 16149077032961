import React, { useEffect, useRef } from "react";
import s from "./BgLine14.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const BgLine14 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      gsap.from(lineRef.current, {
        drawSVG: "0% 0%",
        duration: 1,
        ease: "none",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: "125% 35%+=100px",
          once: true
        }
      });
    }, 1500);
    return () => timer && clearTimeout(timer);
  }, []);

  return (
    <div id="section14__draw" className={s.section14__draw}>
      <svg
        className={s.path__draw__1}
        width="326"
        height="324"
        viewBox="0 0 326 324"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={lineRef}
          className="section__draw"
          opacity="0.2"
          d="M0 1H124.5C234.957 1 324.5 90.543 324.5 201V323.663"
          stroke="#1B52AD"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};
