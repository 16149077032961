import { Image } from "components/contentful/image/Image";
import { Container } from "components/container/Container";
import React from "react";
import s from "./PartnersGrid.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import richTextOptions from "utils/richTextOptions";

const GridColumn = ({ item }: any) => {
  const items = item.items.slice(0,-1);
  const title = item.items[items.length]
  return (
    <div className={s.column}>
      <div className={s.logoGrid}>
      {items.map((el: any, index: number) =>  (
        <div>
          <div className={s.logoContainer}>
            <Image className={s.logo} image={el.image} />
          </div>
        </div>
        ))
      }
      </div>
      <div className={s.infoContainer}>
        <h3 className={s.title}>{title.title}</h3>
        <div className={s.text}>
          {renderRichText(title.text, richTextOptions)}
        </div>
      </div>
    </div>
  );
};

export const PartnersGrid = ({ listData }: any) => {
  const columns = listData.subLists;

  return (
    <Container>
      <div className={s("container")}>
        {columns.map((item: any, index: number) => {
          return <GridColumn item={item} key={index} />;
        })}
      </div>
    </Container>
  );
};
